import { masterSessionStore } from './../store/masterSessions'
import NotificationController from '~/controllers/notifications'
import { viewsStore } from '~/store/views'
import { usersStore } from '~/store/users'
import { workflowStore } from '~/store/workflows'
import { siteStore } from '~/store/site'
import { settingsStore } from '~/store/settings'
import { documentSettingsStore } from '~/store/documentSettings'
import { workplaceStore } from '~/store/workplaces'
import { lookupStore } from '~/store/lookups'
import { sessionStore } from '~/store/sessions'
import apiHelper from '~/helpers/ApiHelper'
import { alertsStore } from '~/store/alerts'
import { rolesStore } from '~/store/roles'
import { routingStore } from '~/store/routing'
import { groupsStore } from '~/store/groups'
import { productsStore } from '~/store/products'
import { operationsStore } from '~/store/operations'
import { deviceStore } from '~/store/devices'
import { actionsStore } from '~/store/actions'
import { projectsStore } from '~/store/projects'
import { onSnapShotUsers } from '~/controllers/users'

class Startup {
  bootstrap = async () => {
    const user = usersStore().user

    // These 2 have to be called first because of RBAC
    await rolesStore().loadRoles()
    await settingsStore().loadSettings()
    await siteStore().loadSite()
    await documentSettingsStore().loadDocumentSettings()

    await sessionStore().loadSessions()
    await workflowStore().loadWorkflows()
    await viewsStore().loadViews()

    await Promise.all([
      usersStore().loadUsers(),
      workplaceStore().loadWorkplaces(),
      masterSessionStore().loadMasterSessions(),
      groupsStore().loadGroups(),
      lookupStore().loadLookups(),
      alertsStore().loadAlerts(),
      routingStore().loadRouting(),
      productsStore().loadProducts(),
      operationsStore().loadOperations(),
      deviceStore().loadDevices(),
      actionsStore().loadActions(),
      projectsStore().loadProjects(),
    ])

    onSnapShotUsers(user)
    NotificationController.setupNotification(user)
    apiHelper.updateCache()
    apiHelper.reloadUserCache()
  }
}

const startup = new Startup()

export default startup
