import { JUser } from '~/models/users/JUser'
import DbHelper from '~/helpers/dbHelper'
import { usersStore } from '~/store/users'
import { USERS_COLLECTION_NAME } from '~/config/storage'

const dbHelper = new DbHelper()

export const onSnapShotUsers = (user: JUser) => {
  dbHelper.setupCollectionSnapshot({
    collectionName: USERS_COLLECTION_NAME,
    checks: [
      {
        field: 'client_id',
        compare: '==',
        value: user.client_id,
      },
      {
        field: 'site_id',
        compare: '==',
        value: user.site_id,
      },
    ],
    callback: async (users: JUser) => {
      await usersStore().loadUsers(true)
    },
  })
}
